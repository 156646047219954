import React, { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react/cjs/react.production.min'

//STYLE
import './ClientsScreen.css'

//IMAGES
import BACKG1 from '../../IMG/RESOURCES/backg1.png'
import EDIT from '../../IMG/RESOURCES/EDIT.png'
import DELETE from '../../IMG/RESOURCES/CLOSE.png'
import PopUpForm from '../../Components/PopUps/PopUpForms/PopUpForm'
import Context from '../../Context/Context'

const ClientsScreen = () => {
    //CONTEXT
    const { getClients, kaanClients } = useContext(Context)

    useEffect(async() => {
        await getClients(
            sessionStorage.getItem('token')
        )
    }, [])

    const [formType, setFormType] = useState('')
    const [itemToEdit, setItemToEdit] = useState('')
    const [showForm, setShowForm] = useState(false)

    const openForm = () => {
        setShowForm(true)
        setFormType('clients')
    }

    const closeForm = () => {
        setShowForm(false)
    }

    const session = sessionStorage.getItem('session')

  return (
    <Fragment>
        <div class="clients-container">
            <img src={BACKG1}></img>
            <div class="clients-tittle-mrgn" data-aos="fade-left">
                <div class="clients-tittle">
                    <span>Clientes</span>
                    <div 
                        class={session ? "tittle-edit-btn" : "hidden"}
                        onClick={(e) => {openForm()}}
                    >
                        <img src={EDIT}></img>
                    </div>
                </div>
            </div>
        </div>

        <div class="clients-list">
            {
                kaanClients && (
                    <Fragment>
                        {
                            kaanClients.map((i) => (
                                <Fragment>
                                    <div class="client-x">
                                        <div class="client-tittle">
                                            <span>{i.name}</span>
                                        </div>
                                        <div class="client-desc">
                                            <img src={i.picture}></img>
                                            <p>{i.description}</p>
                                        </div>
                                        {/* <div class="kaan-more-info-btn">
                                            <div class="kaan-more-info-btn-container">
                                                <div class="plus-btn">+</div>
                                                <div class="more-info-btn">More Information</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </Fragment>
                            ))
                        }
                    </Fragment>
                )
            }
        </div>

        {/* Edit Products Form */}
        <PopUpForm
                form={formType}
                isOpen={showForm}
                closeForm={closeForm}
                data={kaanClients}
            />
    </Fragment>
  )
}

export default ClientsScreen