import React, { useContext, Fragment } from 'react'
import Context from '../../../Context/Context'

// style
import './ConfirmPopUp.css'

const ConfirmPopUp = () => {
    //CONTEXT
    const { 
        item, 
        req, 
        confirmMsg, 
        ShowConfirmMsg, 
        
        delProduct,
        delClient
    } = useContext(Context)

    // delete product
    const deleteProduct = async() => {

        if(req == 'product'){
            await delProduct(
                sessionStorage.getItem('token'),
                item.productId
            )
        }else if(req == 'client'){
            await delClient(
                sessionStorage.getItem('token'),
                item.clientId
            )
        }

    }

//${confirmMsg.length > 0 ? 'popup-open' : ''}
  return (
    <Fragment>
        <div class={`popup ${confirmMsg ? 'popup-open' : ''}`}>
            <div class="popupMsg">
                <div class="msg-container">
                    <div class="msg">
                        <label>Are you Sure?</label>
                    </div>
                    <div class="confirm-options">
                        <div 
                            class="cancel-op"
                            onClick={(e) => {
                                ShowConfirmMsg(false, [])
                            }}
                        > 
                            Cancel
                        </div>
                        <div 
                            class="confirm-op" 
                            onClick={(e) => (
                                deleteProduct()
                            )}
                        > 
                            Confirm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default ConfirmPopUp