import React, { Fragment } from 'react'
import './Services.css'

const Services = () => {
  return (
    <Fragment>
        <div class="kaan-home-services">
            <div class="home-services-section1"></div>
            <div class="home-services">
                <div class="home-services-tittle">
                    Nuestros Servicios 
                </div>
                <div class="home-services-text">
                    <label>
                        Contamos con un equipo de especialistas con mas de 15 años en el desarrollo de soluciones
                        para todo tipo de industrias utilizando tecnología.

                    </label>

                    <div class="home-services-tecno">
                        <p>
                            <ul>
                                <li>
                                    SQL
                                </li>
                                <li>
                                    Oracle
                                </li>
                                <li>
                                    Tableau
                                </li>
                                <li>
                                    Azure
                                </li>
                                <li>
                                    Xamarin
                                </li>
                                <li>
                                    VS Y .NET
                                </li>
                                <li>
                                    TFS
                                </li>
                            </ul>
                        </p>

                        <p>
                            <ul>
                                <li>
                                    PMI
                                </li>
                                <li>
                                    SCRUM
                                </li>
                                <li>
                                    TSP/PSP
                                </li>
                                <li>
                                    XP
                                </li>
                                <li>
                                    Desarrollo LEN
                                </li>
                                <li>
                                    ITIL
                                </li>
                                <li>
                                    Six Sigma
                                </li>
                            </ul>
                        </p>
                        <p>
                            <ul>
                                <li>
                                    CMMI
                                </li>
                                <li>
                                    ISTQB
                                </li>
                                <li>
                                    IIBA
                                </li>
                            </ul>
                        </p>
                    </div>
                </div> 
                <div 
                    class="cards-services" data-aos="fade-left" mirror="true">
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="card-tittle">
                                Servicios TI
                            </div>
                            <div class="card-desc">
                                <p>
                                    <ul>
                                        <li>
                                            Desarrollo Software
                                        </li>
                                        <li>
                                            Soluciones Móviles
                                        </li>
                                        <li>
                                            Integración aplicativos
                                        </li>
                                        <li>
                                            Testing
                                        </li>
                                        <li>
                                            Soporte de 1°, 2° y 3° nivel
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="scard-tittle">
                                Infraestructura TI
                            </div>
                            <div class="card-desc">
                                <p>
                                <ul>
                                    <li>
                                        Admin. de bases de datos
                                    </li>
                                    <li>
                                        AMS (Legados o desarrollados por nosotros)
                                    </li>
                                    <li>
                                        Cableado estructurado
                                    </li>
                                    <li>
                                        Admin. de nube y servidores
                                    </li>
                                </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="card-tittle">
                                Inteligencia Empresarial
                            </div>
                            <div class="card-desc">
                                <p>
                                <ul>
                                    <li>
                                        Admin. de proyectos
                                    </li>
                                    <li>
                                        Head hunting
                                    </li>
                                    <li>
                                        Identidad corporativa
                                    </li>
                                    <li>
                                        Coaching
                                    </li>
                                    <li>
                                        BPA y BPM
                                    </li>
                                </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="home-services-section2"></div>
        </div>
    </Fragment>
  )
}

export default Services