import {React, useState, useContext} from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Context from '../../../Context/Context'

import './LoginForm.css'

const LoginForm = () => {

    //CONTEXT
    const { login, userData } = useContext(Context)

    const [message, setMessage] = useState("")

    const [loginData, setLoginData] = useState({})

    // validation
    const check = async(e) => {
        e.preventDefault()

        if(!loginData.username){
            setMessage('Username required')
            loginData.username('')
            //setLoading(false)
            return
        }

        if(!loginData.password){
            setMessage('Password required')
            loginData.password('')
            //setLoading(false)
            return
        }

        const loginBody = {
            user: loginData.username,
            password: loginData.password
        }

        await login(
            sessionStorage.getItem("token"),
            loginBody
        )

        if(sessionStorage.getItem("session") == "sucess"){
            setMessage("")
        }else{
            setMessage("User Not Found")
        }
    }

  return (
    <Fragment>
        <div class="login-form-container">
            <div class="form-tittle">
                <span>Log In</span>
                <label class="err-msg">{message}</label>
            </div>


            <div class="username-input">
                <label 
                    class={ message == "Username required" ? "login-span-error" : "login-span" }
                >
                    Username
                </label>
                <input 
                    type="text" 
                    autoFocus
                    class={ message == "Username required" ? "input-error" : "input" }
                    onChange={(e) => {
                            loginData.username = e.target.value
                            setMessage(" ")
                        }
                    }
                />
            </div>

            <div class="password-input">
                <label 
                    class={ message == "Password required" ? "login-span-error" : "login-span" }
                >
                    Password
                </label>
                <input 
                    type="password" 
                    autoFocus
                    class={ message == "Password required" ? "input-error" : "input" }
                    onChange={(e) => {
                            loginData.password = e.target.value
                            setMessage(" ")
                        }
                    }
                />
            </div>

            <div class="form-send-btn" onClick={(e) => {check(e)}}>
                <input
                    type='submit'
                    value={"Go"}
                ></input>
            </div>
        </div>
    </Fragment>
  )
}

export default LoginForm