import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'

//STYLE
import './JobBank.css'

import LOGOKAANW from'../../IMG/LOGO/LogoKaanBlanco.png'

//IMAGES
import BACKG1 from '../../IMG/RESOURCES/backg1.png'

const JobBank = () => {
  return (
    <Fragment>
        <div class="clients-container">
            <img src={BACKG1}></img>
            <div class="clients-tittle-mrgn" data-aos="fade-left">
                <div class="clients-tittle2">
                    <span>Bolsa de Trabajo</span>
                </div>
            </div>
        </div>
        <div class="clients-list">
        <br/><br/><br/><br/>
            <div class="client-x">
                <span>Próximamente...</span>
                <br/><br/>
                <p>
                    Aquí encontrarás todas nuestras vacantes.
                </p>
                <br/><br/><br/><br/>
            </div>
        </div>

        {/* <table
            align='center'
            width='100%'
        >
            <tr>
                <td
                    colspan='2'
                    align='center'
                >
                        <img class="table-img" src={HEADERNEW}/>
                        <div class="tb-tittle">
                            <label>Información Solicitada</label>
                        </div>
                </td>
            </tr>


            <tr>
                <td 
                    align='right'
                    width='50%'
                >
                    <div class="client-data-div">
                        <tr>
                            <label>Name(s)</label>
                        </tr>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
                <td 
                    align='left'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Last Name(s)</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 

            <tr>
                <td
                    align='right'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Email</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>  
                </td>
                <td
                    align='left'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Business</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 

            <tr>
                <td
                    colspan='2'
                    align='center'
                >
                    <div class="client-data-msg">
                        <label>Message</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 
        </table> */}
        
    {/* <div>
        <div class="mail-container">
            <img src={BACKG1}></img>
            <div class="mail-tittle">
                <div class="mail-img-container">
                    <img src="/static/media/LOGOKAAN.ca1d7bd780f4170e60c2.png"/>
                </div>
                <label>Información Solicitada</label>
            </div>
        </div>

table
        <table
            align='center'
            width='100%'
        >
            <tr>
                <td
                    colspan='2'
                    align='center'
                >
                    <tr
                        colspan='2'
                        align='center'
                        height='100px'
                    >
                        <img src={BACKG1}/>
                        <div class="tb-tittle">
                            <label>Información Solicitada</label>
                        </div>
                    </tr>
                </td>
            </tr>


            <tr>
                <td 
                    align='right'
                    width='50%'
                >
                    <div class="client-data-div">
                        <tr>
                            <label>Name(s)</label>
                        </tr>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
                <td 
                    align='left'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Last Name(s)</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 

            <tr>
                <td
                    align='right'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Email</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>  
                </td>
                <td
                    align='left'
                    width='50%'
                >
                    <div class="client-data-div">
                        <label>Business</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 

            <tr>
                <td
                    colspan='2'
                    align='center'
                >
                    <div class="client-data-msg">
                        <label>Message</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </td>
            </tr> 
        </table>




pure flex
        <div 
            class="client-info-sec1"
        >   
            <div class="client-data-div">
                <label>Name(s)</label>
                <div class="data-content">
                    <span></span>
                </div>
            </div>
            <div class="client-data-div">
                <label>Last Name(s)</label>
                <div class="data-content">
                    <span></span>
                </div>
            </div>
        </div>

        <div class="client-info-sec2">
            <div class="client-data-div">
                <label>Email</label>
                <div class="data-content">
                    <span></span>
                </div>
            </div>
            <div class="client-data-div">
                <label>Business</label>
                <div class="data-content">
                    <span></span>
                </div>
            </div>
        </div>

        <div class="client-info-sec3">
            <div class="client-data-msg">
                <label>Message</label>
                <div class="data-content">
                    <span></span>
                </div>
            </div>
        </div>

        <div class="mail-body">
            <div class="mail-data-container">
                <div class="client-data-form">
                    <div class="client-data">
                        <label>Name(s)</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>

                    <div class="client-data">
                        <label>Last Name(s)</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>

                    <div class="client-data">
                        <label>Email</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                    <div class="client-data">
                        <label>Business</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>

                    <div class="client-data-msg">
                        <label>Message</label>
                        <div class="data-content">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mail-footer-container">
            <img src={LOGOKAANW}></img>
        </div>
    </div> */}


    </Fragment>
  )
}

export default JobBank