import React, {useReducer} from 'react';
import axios from 'axios';

import Context from './Context';
import ContextReducer from './ContextReducer';

const ContextState = (props) => {
    const initialState = {
        token: "",

        //products
        kaanInfo: {},
        kaanProducts: [],


        //clients
        kaanClients: [],

        userData: {},

        result: "",

        showLoader: false,
        reqStatus: false,
        confirmMsg: false,
        item: '',
        req: ''
    }

    const [state, dispatch] = useReducer(ContextReducer, initialState)

    //URLConfig
    const URLexp = "https://us-central1-kaanmx-ecf3b.cloudfunctions.net"
    const xclientId = "7"
    const xclientSecret = "VWQmhlU0JGWTI5dGJXVnlZ="

    //TOKEN
    const getToken = async () => {
        try {
            const res = await axios.get(
                (`${URLexp}/token`),
                {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret
                    }
                }
            )
            console.log(res.data.data.token)
            sessionStorage.setItem("token", res.data.data.token)
            dispatch({
                type: 'GET_TOKEN',
                payload: res.data.data.token
            })
        
        } catch (error) {
            console.log(error)
        }
    }

    //GET KAAN INFO
    const getKaanInfo = async (token) => {
        try {
            const res = await axios.get(
                (`${URLexp}/kaan`),
                {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )
            console.log(res.data.data)

            if(res.data.data){
                dispatch({
                    type: 'GET_KAAN_INFO',
                    payload: res.data.data
                })  
            }else{
                dispatch({
                    type: 'GET_KAAN_INFO',
                    payload: []
                })  
            }
        } catch(error) {
            console.log(error)
        }
    }

// admin login
    //post Login
    const login = async (token, body) => {
        showLoaderF()
        try {
            const res = await axios.post(
                (`${URLexp}/admin/login`),
                body, {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )
            
            if(res.data.data){
                dispatch({
                    type: 'LOGIN',
                    payload: res.data.data
                })  
                
                await getUserData(
                    token,
                    res.data.data.userID
                )

                sessionStorage.setItem("session", "sucess")

                window.location = '/'
            }else{
                dispatch({
                    type: 'LOGIN',
                    payload: []
                }) 

                localStorage.setItem("login", "failed")
            }

            hideLoader()


        } catch(error) {
            console.log(error)
        }
    }

    const getUserData = async (token, id) => {
        try {
            const res = await axios.get(
                (`${URLexp}/admin/${id}`),
                {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )

            if(res.data.data){
                dispatch({
                    type: 'GET_USER_DATA',
                    payload: res.data.data
                })
    
                sessionStorage.setItem("admin", res.data.data.name)   
            }
        } catch (error) {
            console.log(error)
        }
    }


//$$$$$$$$$$$$$$$$$$$$$$$$ products
const getProducts = async (token) => {
    showLoaderF()
    try {
        const res = await axios.get(
            (`${URLexp}/product`),
            {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        console.log(res.data.data)

        if(res.data.data){
            dispatch({
                type: 'GET_PRODUCTS',
                payload: res.data.data
            })  
        }else{
            dispatch({
                type: 'GET_PRODUCTS',
                payload: []
            })  
        }

        hideLoader()
    } catch(error) {
        console.log(`OffersState: ${error}`)
    }
}

//post product
    const newProduct = async (token, body, picture) => {
        showLoaderF()

        try {
            const res = await axios.post(
                (`${URLexp}/product/newProduct`),
                body, {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )

            for (var i=0 ; i < picture.length ; i++ ) {
                const FD = new FormData()
                FD.append('file', picture[i])

                console.log(FD)
                console.log(picture)

                await putProductPicture(
                        FD,
                        sessionStorage.getItem("token"),
                        res.data.data.productId
                    )
            }
            
            
            if(res.data.data){
                hideLoader()
                refreshStatus(true, '/Products')

                dispatch({
                    type: 'NEW_PRODUCT',
                    payload: res.data.data
                })

            }else{
                dispatch({
                    type: 'NEW_PRODUCT',
                    payload: []
                })                
                refreshStatus(false, '')
            }


        } catch(error) {
            console.log(error)
        }
    }

// put product picture
    const putProductPicture = async (file, token, pid) => {
        try {
            const res = await axios.put(
                (`${URLexp}/product/${pid}/image`),
                file, {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )
            console.log(res.data)
            dispatch({
                type: 'PUT_PRODUCT_IMAGES',
                payload: res.data
            })
        } catch(error) {
            console.log(error)
        }
    }

//delete product
    const delProduct = async (token, pid) => {
        ShowConfirmMsg(false, [])
        showLoaderF()
        try {
            const res = await axios.delete(
                (`${URLexp}/product/${pid}`),
                {
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )
            console.log(res.data)

            dispatch({
                type: 'DELETE_PRODUCT',
                payload: res.data.data
            })
        
            hideLoader()
            window.location = '/Products'
        
        } catch (error) {
            hideLoader()
            console.log(error)
        }
    }


//DELETE PRODUCT IMAGE
    const delProductIMG = async (token, pid, body) => {
        try {
            const res = await axios.delete(
                (`${URLexp}/product/${pid}/image`),
                { 
                    data: body,
                    headers: {
                        "x-client-id": xclientId,
                        "x-client-secret": xclientSecret,
                        token
                    }
                }
            )
            console.log(res.data)

            dispatch({
                type: 'DELETE_PRODUCT_IMG',
                payload: res.data.data
            })
        
        } catch (error) {
            console.log(error)
        }
    }


// mailer
//post product
const sendEmail = async (token, body) => {
    // showLoader()

    try {
        const res = await axios.post(
            (`${URLexp}/mailer/sendEmail`),
            body, {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        
        if(res.data.data){
            //refreshStatus(true, '/Products')

            dispatch({
                type: 'SEND_EMAIL',
                payload: res.data.data
            })

            window.location='/'

        }else{
            dispatch({
                type: 'SEND_EMAIL',
                payload: []
            })                
            refreshStatus(false, '')

        }

        // hideLoader()
    } catch(error) {
        console.log(error)
    }
}


//$$$$$$$$$$$$$$$$$$$$$$$$ clients
const getClients = async (token) => {
    showLoaderF()
    try {
        const res = await axios.get(
            (`${URLexp}/client`),
            {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        console.log(res.data.data)

        if(res.data.data){
            dispatch({
                type: 'GET_CLIENTS',
                payload: res.data.data
            })  
        }else{
            dispatch({
                type: 'GET_CLIENTS',
                payload: []
            })  
        }

        hideLoader()
    } catch(error) {
        console.log(`OffersState: ${error}`)
    }
}

//post client
const newClient = async (token, body, picture) => {
    showLoaderF()

    try {
        const res = await axios.post(
            (`${URLexp}/client/newClient`),
            body, {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )

        for (var i=0 ; i < picture.length ; i++ ) {
            const FD = new FormData()
            FD.append('file', picture[i])

            console.log(FD)
            console.log(picture)

            await putClientPicture(
                    FD,
                    sessionStorage.getItem("token"),
                    res.data.data.clientId
                )
        }
        
        
        if(res.data.data){
            hideLoader()
            refreshStatus(true, '/OurClients')

            dispatch({
                type: 'NEW_CLIENT',
                payload: res.data.data
            })

        }else{
            dispatch({
                type: 'NEW_CLIENT',
                payload: []
            })                
            refreshStatus(false, '')
        }


    } catch(error) {
        console.log(error)
    }
}

// put product picture
const putClientPicture = async (file, token, pid) => {
    try {
        const res = await axios.put(
            (`${URLexp}/client/${pid}/image`),
            file, {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        console.log(res.data)
        dispatch({
            type: 'PUT_CLIENT_IMAGES',
            payload: res.data
        })
    } catch(error) {
        console.log(error)
    }
}

//delete product
const delClient = async (token, pid) => {
    ShowConfirmMsg(false, [])
    showLoaderF()
    try {
        const res = await axios.delete(
            (`${URLexp}/client/${pid}`),
            {
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        console.log(res.data)

        dispatch({
            type: 'DELETE_CLIENT',
            payload: res.data.data
        })
    
        hideLoader()
        window.location = '/OurClients'
    
    } catch (error) {
        hideLoader()
        console.log(error)
    }
}


//DELETE PRODUCT IMAGE
const delClientIMG = async (token, cid, body) => {
    try {
        const res = await axios.delete(
            (`${URLexp}/client/${cid}/image`),
            { 
                data: body,
                headers: {
                    "x-client-id": xclientId,
                    "x-client-secret": xclientSecret,
                    token
                }
            }
        )
        console.log(res.data)

        dispatch({
            type: 'DELETE_CLIENT_IMG',
            payload: res.data.data
        })
    
    } catch (error) {
        console.log(error)
    }
}


//$$$$$$$$$$$$$$$$$$$$$$$$ popups
// loader
    const showLoaderF = () => {
        dispatch({
            type: 'SHOW_LOADER',
            payload: true
        })
    } 

    const hideLoader = () => {
        dispatch({
            type: 'SHOW_LOADER',
            payload: false
        })  
    }

// status pop up
    const refreshStatus = (status, route) => {
        sessionStorage.setItem('reqMsg', true)
        if(status){
            dispatch({
                type: 'REQ_STATUS',
                payload: true
            })

            setTimeout(() => {
                dispatch({
                    type: 'REQ_STATUS',
                    payload: false
                })
            }, 2500);

            window.location = route
            
        }else{
            sessionStorage.setItem('reqMsg', false)

            dispatch({
                type: 'REQ_STATUS',
                payload: true
            })
        }
    }

// confirm status
    const ShowConfirmMsg = (show, item, req) => {
        console.log(show)
        dispatch({
            type: 'SHOW_MSG',
            payload: show
        })

        dispatch({
            type: 'ITEM',
            payload: item
        })

        dispatch({
            type: 'REQUEST',
            payload: req
        })
    }

    return <Context.Provider
        value = {{
            token: state.token,
            userData: state.userData,

            //products
            kaanProducts: state.kaanProducts,


            //clients
            kaanClients: state.kaanClients,


            reqStatus: state.reqStatus,
            showLoader: state.showLoader,
            confirmMsg: state.confirmMsg,
            item: state.item,
            req: state.req,
            
            getToken,
            login,
            getUserData,


            //products
            getProducts,
            newProduct,
            delProduct,
            delProductIMG,


            //mailer
            sendEmail,


            //clients
            getClients,
            newClient,
            delClient,
            delClientIMG,


            ShowConfirmMsg,

            showLoaderF,
            hideLoader
        }}
    >
        {props.children}
    </Context.Provider>
}
export default ContextState;