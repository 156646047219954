import React, { useContext } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Context from '../../Context/Context'

//STYLE
import './Loader.css'

const Loader = () => {
    //CONTEXT
    const { showLoader } = useContext(Context)

  return (
    <Fragment>
        <div class={`loader ${showLoader ? 'show' : ''}`}>
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </Fragment>
  )
}

export default Loader