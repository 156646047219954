import {React, Fragment, useEffect, useContext, useState} from 'react'


//STYLE
import './Products.css'

//IMAGES
import BACKG1 from '../IMG/RESOURCES/backg1.png'
import Context from '../Context/Context'
import EDIT from '../IMG/RESOURCES/EDIT.png'


import PopUpForm from '../Components/PopUps/PopUpForms/PopUpForm.js'

const Products = () => {
    //CONTEXT
    const { getProducts, kaanProducts } = useContext(Context)

    useEffect(async() => {

        await getProducts(
            sessionStorage.getItem('token')
        )
    }, [])

    const [showForm, setShowForm] = useState(false)

    const openForm = () => {
        setShowForm(true)
    }

    const closeForm = () => {
        setShowForm(false)
    }


    const session = sessionStorage.getItem('session')
    return (
        <Fragment>
            {
                kaanProducts && (
                    <Fragment>
                        <div class="clients-container">
                            <img src={BACKG1}></img>
                            <div class="clients-tittle-mrgn" data-aos="fade-left">
                                <div class="clients-tittle">
                                    <span>Productos</span>
                                    <div 
                                        class={session ? "tittle-edit-btn" : "hidden"}
                                        onClick={(e) => {openForm()}}
                                    >
                                        <img src={EDIT}></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="products-list">
                            {
                                kaanProducts.map((i) => (
                                    <div class="prod-x">
                                        <span>{i.name}</span>
                                        <img src={i.picture}></img>
                                        <p>{i.description}</p>
                                        <div class="kaan-more-info-btn">
                                            {/* <div class="kaan-more-info-btn-container">
                                                <div class="plus-btn">+</div>
                                                <a href={i.link}>
                                                    <div class="more-info-btn">More Information</div>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Fragment>
                )
            }
            {
                !kaanProducts && (
                    <label>Loading Data...</label>
                )
            }

        {/* Products Form */}
            <PopUpForm
                form="products"
                isOpen={showForm}
                closeForm={closeForm}
                data={kaanProducts}
            />
        </Fragment>
    )
}

export default Products