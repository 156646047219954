import {React, Fragment} from 'react'

const UserModal = () => {
  return (
    <Fragment>
        <label>
            {sessionStorage.getItem('admin')}
        </label>
    </Fragment>
  )
}

export default UserModal