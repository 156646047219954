import React, { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Context from '../../../../Context/Context'

const NewClientForm = () => {
    //CONTEXT
    const { newClient } = useContext(Context)

    const [pictures, setPictures] = useState([])

    let picturesClone = []
    let picturesArray = []

    useEffect(async () => {
        
        setTimeout(() => {
            const file = document.getElementById('uploadPicture')

            file.addEventListener('change', (e) => {
                setMessage(" ")
                const pictContainer = document.getElementById('picturesContainer')
                pictContainer.className = "addp-upload-picture"

                picturesArray = Array.from(file.files)

                for (var i=0 ; i < file.files.length ; i++ ) {
                    var img_id = (Math.floor(Math.random() * (30000 + 10000)) + '_' + Date.now())
                    createImg(file, i, img_id)
                }
                
                if(picturesClone.length <= 0) {

                    picturesClone = picturesArray
                    setPictures(picturesClone)

                    return
                }

                picturesClone = picturesClone.concat(picturesArray)
                setPictures(picturesClone)

            })

            const createImg = (file, i, img_id) => {
                //CREATING IMG DIV
                const imgContainer = document.createElement('div')
                imgContainer.classList.add('newImgDiv', img_id)
                imgContainer.dataset.id = img_id

                //COUNT ELEMENTS
                let childDivs = document.getElementById('preview-images').childElementCount

                document.getElementById('preview-images').appendChild(imgContainer)
                
                for (var r=0 ; r<=childDivs ; r++) {
                    document.getElementById('preview-images').children[r].dataset.name = r
                }
            
                //CREATING IMG
                const newImg = document.createElement('img')
                newImg.classList.add('newAddImg', img_id)
                newImg.dataset.id = img_id
            
                newImg.src = (URL.createObjectURL(file.files[i]))
            
                document.getElementsByClassName(img_id)[0].appendChild(newImg)
            
                //CREATING DELETE BUTTON
                const closeButton = document.createElement('div')
                closeButton.classList.add('del-img-btn-add')
                closeButton.dataset.id = img_id
                closeButton.innerText = 'x'
            
                closeButton.onclick = (e) => {
                    const ielement = closeButton.parentNode.dataset.name
                    const delElement = picturesClone.splice(ielement, 1)
                    const newPicturesArray = picturesClone.filter(i => i != delElement)

                    picturesArray = newPicturesArray
                    picturesClone = newPicturesArray

                    setPictures(picturesClone)

                    closeButton.parentNode.remove()

                    //COUNT ELEMENTS
                    let childDivs = document.getElementById('preview-images').childElementCount

                    for (var r=0 ; r<childDivs ; r++) {
                        document.getElementById('preview-images').children[r].dataset.name = r
                    }
                }
            
                document.getElementsByClassName(img_id)[0].appendChild(closeButton)
            
            }
        }, 2000);
    }, [])

    const [newClientData, setNewClientData] = useState({})

    const postNewClient = async() => {
        if(pictures.length <= 0) {
            setMessage('Client Images Required')

            const pictContainer = document.getElementById('picturesContainer')
            pictContainer.className = "addp-upload-picture-error"
    
            return
        } 

        const newClientBody = {
            name: newClientData.name,
            description: newClientData.description,
        }

        await newClient(
            sessionStorage.getItem('token'),
            newClientBody,
            pictures
        )
    }

    const [message, setMessage] = useState('')


  return (
    <Fragment>
        <div class="new-prod-form-container">
            <div class="new-prod-input-container">
                <div class="form-tittle">
                    <span>New Client</span>
                    <label class="err-msg">{message}</label>
                </div>

                <div class="edit-prod-input-container">
                    <div class="addp-images">
                        <div class="addp-images">
                            <div class="addp-upload-picture" id="picturesContainer">
                                <form enctype="multipart/form-data" method="POST" class="addp-form-user-picture">
                                    <input type="file" id="uploadPicture" name="inputFile" accept="image/*" multiple=""/>
                                </form>
                                <p>
                                    <br/>Drag Image Or Click Here <br/> To Add
                                </p>
                                <div 
                                    id="preview-images"
                                    class="addp-carousel"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div class="input-cont-2">
                        <div class="prodname-input">
                            <label 
                                class={ message == "Username required" ? "login-span-error" : "login-span" }
                            >
                                Client Name
                            </label>
                            <input 
                                type="text" 
                                autoFocus
                                class={ message == "Username required" ? "input-error" : "input" }
                                onChange={(e) => {
                                        newClientData.name = e.target.value
                                    }
                                }
                            />
                        </div>

                        <div class="proddesc-input">
                            <label 
                                class={ message == "Username required" ? "login-span-error" : "login-span" }
                            >
                                Description
                            </label>
                            <textarea 
                                type="text" 
                                autoFocus
                                class={ message == "Username required" ? "input-error" : "input" }
                                onChange={(e) => {
                                        newClientData.description = e.target.value
                                    }
                                }
                            />
                        </div>

                        <div class="input-cont-3">
                            <div class="form-send-btn" onClick={(e) => {postNewClient()}}>
                                <input
                                    type='submit'
                                    value="Confirm"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default NewClientForm