import {React, useState, useEffect} from 'react'
import { Fragment } from 'react/cjs/react.production.min'

import LoginForm from '../../Forms/LoginForm/LoginForm'

//IMAGES
import CLOSE from '../../../IMG/RESOURCES/CLOSE.png'
import UserModal from '../../Forms/UserModal/UserModal'
import ProdsForm from '../../Forms/ProdsForm/ProdsForm'
import EditProductForm from '../../Forms/ProdsForm/EditProduct/EditProductForm'
import NewProdForm from '../../Forms/ProdsForm/NewProduct/NewProdForm'
import ClientsForm from '../../Forms/ClientsForm/ClientsForm'
import EditClientForm from '../../Forms/ClientsForm/EditClient/EditClientForm'
import NewClientForm from '../../Forms/ClientsForm/NewClient/NewClientForm'

const PopUpForm = ({form, isOpen, closeForm, data}) => {

  return (
    <Fragment>
        <div class={`form ${isOpen && 'form-open'}`}>
            <div class="form-container">
                <div class="form-close">
                    <img 
                        src={CLOSE}
                        onClick={closeForm}
                    ></img>
                </div>
                {
                // login
                    form == "login" ? (
                        <LoginForm/>
                    ) : (
                        form == "loginSucess" ? (
                            <UserModal/>
                        ) : (
                // products 
                            form == "products" ? (
                                <ProdsForm
                                    products={data}
                                />
                            ) : (
                                form == "editProduct" ? (
                                    <EditProductForm
                                        product={data}
                                    />
                                ) : (
                                    form == "newProduct" ? (
                                        <NewProdForm/>
                                    ) : (
                // clients
                                        form == "clients" ? (
                                            <ClientsForm
                                                clients={data}
                                            />
                                        ) : (
                                            form == "editClient" ? (
                                                <EditClientForm
                                                    client={data}
                                                />
                                            ) : (
                                                form == "newClient" ? (
                                                    <NewClientForm/>
                                                ) : (
                                                    <Fragment></Fragment>
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }
            </div>
        </div>
    </Fragment>
  )
}

export default PopUpForm