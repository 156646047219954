import {React, useState, useContext, useEffect} from 'react'
import { Fragment } from 'react/cjs/react.production.min'

//STYLE
import './ClientsForm.css'

//IMG
import EDIT from '../../../IMG/RESOURCES/EDIT.png'
import DELETE from '../../../IMG/RESOURCES/CLOSE.png'
import Context from '../../../Context/Context'
import PopUpForm from '../../PopUps/PopUpForms/PopUpForm'

const ClientsForm = ({clients}) => {
    //CONTEXT
    const { delProduct, ShowConfirmMsg } = useContext(Context)

    const [showForm, setShowForm] = useState(false)
    const [formType, setFormType] = useState('')
    const [itemToEdit, setItemToEdit] = useState('')

    useEffect(() => {
      sessionStorage.setItem('reqStatus', 'NA')
      console.log(clients)
    }, [])
    

    const openForm = (client) => {
        setShowForm(true)   
        setItemToEdit(client)
    }

    const closeForm = () => {
        setShowForm(false)
        setItemToEdit('')
    }

    //delete product
    const deleteProduct = async(item) => {
        ShowConfirmMsg(true, item, 'client')
    }

  return (
    <Fragment>
        <div class="products-form-container">
            
            <div class="form-tittle">
                <span>Clients</span>
                {/* <label class="err-msg">{message}</label> */}
            </div>

            <div class="form-prods-list">
                {
                    clients.map((item) => (
                        <Fragment>
                            <div class="products-item">
                                <div class="form-prod-img">
                                    <img src={item.picture}></img>
                                </div>

                                <div class="form-prod-tittle">
                                    <h2>{item.name}</h2>
                                </div>

                                <div class="form-controls">
                                    <div class="edit-btn">
                                        <img 
                                            src={EDIT} 
                                            title="Editar"
                                            onClick={(e) => {
                                                openForm(item)
                                                setFormType("editClient")
                                            }}
                                        ></img>
                                    </div>

                                    <div class="delete-btn">
                                        <img 
                                            src={DELETE} 
                                            title="Eliminar"
                                            onClick={(e) => {
                                                closeForm()
                                                deleteProduct(item)
                                            }}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ))
                }
            </div>

            <div class="form-send-btn" 
                onClick={(e) => {
                    openForm('')
                    setFormType('newClient')
                }}
            >
                <input  
                    type='submit'
                    value="Add Client"
                ></input>
            </div>

            {/* Edit Products Form */}
            <PopUpForm
                form={formType}
                isOpen={showForm}
                closeForm={closeForm}
                data={itemToEdit}
            />
        </div>
    </Fragment>
  )
}

export default ClientsForm