import React, { Fragment, useContext, useEffect } from 'react'
import Contact from '../../Components/Contact/Contact'
import Context from '../../Context/Context'

//STYLE
import './HomeScreen.css'

const HomeScreen = () => {
    //CONTEXT
    const { getToken, getProducts, hideLoader } = useContext(Context)

    useEffect(async() => {
        await getToken()

        await getProducts(
            sessionStorage.getItem('token')
        )

        hideLoader()
    }, [])
    

  return (
    <Fragment>
        <div class="body-container">
            <div 
                class="kaan-info" 
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1500"
            >
                <div class="kaan-info-tittle">
                    <label>¿Quiénes somos?</label>
                </div>
                <div class="kaan-info-body">
                    <label class="points-label">.........</label>
                    <label class="kaan-info-label">
                        En Kaan somos una empresa dedicada a brindar soluciones TI utilizando herramientas de tecnología, metodologías y personal capacitado.
                        <br/><br/>
                        Con mas de 10 años de experiencia en el Desarrollo, Implementación y Administración de proyectos.
                    </label>
                </div>
                {/* <div class="kaan-more-info-btn">
                    <div class="kaan-more-info-btn-container">
                        <div class="plus-btn">+</div>
                        <div class="more-info-btn">More Information</div>
                    </div>
                </div> */}
            </div>
            <div class="body-animation"></div>
            <div class="body-info">
                <div class="margen-amarillo"></div>
            </div>
        </div>
        <div class="kaan-home-services">
            <div class="home-services-section1"></div>
            <div class="home-services">
                <div class="home-services-tittle">
                    Nuestros servicios 
                </div>
                <div class="home-services-text">
                    <label>
                        Contamos con un equipo de especialistas con mas de 15 años en el desarrollo de soluciones
                        para todo tipo de industrias utilizando tecnología.

                    </label>

                    <div class="home-services-tecno">
                        <p>
                            <ul>
                                <li>
                                    SQL
                                </li>
                                <li>
                                    Oracle
                                </li>
                                <li>
                                    Tableau
                                </li>
                                <li>
                                    Azure
                                </li>
                                <li>
                                    Xamarin
                                </li>
                                <li>
                                    VS Y .NET
                                </li>
                                <li>
                                    TFS
                                </li>
                            </ul>
                        </p>

                        <p>
                            <ul>
                                <li>
                                    PMI
                                </li>
                                <li>
                                    SCRUM
                                </li>
                                <li>
                                    TSP/PSP
                                </li>
                                <li>
                                    XP
                                </li>
                                <li>
                                    Desarrollo LEN
                                </li>
                                <li>
                                    ITIL
                                </li>
                                <li>
                                    Six Sigma
                                </li>
                            </ul>
                        </p>
                        <p>
                            <ul>
                                <li>
                                    CMMI
                                </li>
                                <li>
                                    ISTQB
                                </li>
                                <li>
                                    IIBA
                                </li>
                            </ul>
                        </p>
                    </div>
                </div> 
                <div 
                    class="cards-services" data-aos="fade-left" mirror="true">
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="card-tittle">
                                Servicios TI
                            </div>
                            <div class="card-desc">
                                <p>
                                    <ul>
                                        <li>
                                            Desarrollo Software
                                        </li>
                                        <li>
                                            Soluciones Móviles
                                        </li>
                                        <li>
                                            Integración aplicativos
                                        </li>
                                        <li>
                                            Testing
                                        </li>
                                        <li>
                                            Soporte de 1°, 2° y 3° nivel
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="scard-tittle">
                                Infraestructura TI
                            </div>
                            <div class="card-desc">
                                <p>
                                <ul>
                                    <li>
                                        Admin. de bases de datos
                                    </li>
                                    <li>
                                        AMS (Legados o desarrollados por nosotros)
                                    </li>
                                    <li>
                                        Cableado estructurado
                                    </li>
                                    <li>
                                        Admin. de nube y servidores
                                    </li>
                                </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                    <div class="card-mrgn">
                        <div class="card">
                            <div class="card-tittle">
                                Inteligencia Empresarial
                            </div>
                            <div class="card-desc">
                                <p>
                                <ul>
                                    <li>
                                        Admin. de proyectos
                                    </li>
                                    <li>
                                        Head hunting
                                    </li>
                                    <li>
                                        Identidad corporativa
                                    </li>
                                    <li>
                                        Coaching
                                    </li>
                                    <li>
                                        BPA y BPM
                                    </li>
                                </ul>
                                </p>
                            </div>
                            {/* <div class="card-more-info">More info...</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="home-services-section2"></div>
        </div>
        {/* <div class="home-client-reviews">
            <div class="carousel-right">{">"}</div>
            <div class="carousel-left">{"<"}</div>
            <div class="client-carousel-container">
                <div class="carousel-tittle">Client Review</div>
                <div class="carousel-img">
                    <img></img>
                </div>
                <div class="review-body">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
                <div class="client-info">
                    <span>José Spector Dir.</span>
                    <span>General de ML Asociados</span>
                </div>
            </div>
        </div> */}
        <div class="margen-amarillo"></div>
        <div class="home-contact-us">
            <Contact/>
        </div>
    </Fragment>
  )
}

export default HomeScreen