export const GET_TOKEN = 'GET_TOKEN'

//PRODUCTS
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const NEW_PRODUCT = 'NEW_PRODUCT'
export const DELETE_PRODUCT = 'DEL_PRODUCT'
export const PUT_PRODUCT_IMAGES = 'PUT_PRODUCT_IMAGES'
export const DELETE_PRODUCT_IMG = 'DEL_PRODUCT_IMG'

// clients
export const GET_CLIENTS = 'GET_CLIENTS'
export const NEW_CLIENT = 'NEW_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_IMG = 'DELETE_CLIENT_IMG'
export const PUT_CLIENT_IMAGES = 'PUT_CLIENT_IMAGES'


// admin login
export const LOGIN = 'LOGIN'
export const GET_USER_DATA = 'GET_USER_DATA'


// mailer
export const SEND_EMAIL = 'SEND_EMAIL'


// status 
export const REQ_STATUS = 'REQ_STATUS'
export const SHOW_LOADER = 'SHOW_LOADER'
export const SHOW_MSG = 'SHOW_MSG'
export const ITEM = 'ITEM'
export const REQUEST = 'REQUEST'