import { Fragment, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Fotter from './Components/Footer/Footter';
import Navbar from './Components/Navbar/Navbar';
import HomeScreen from './Screens/HomeScreen/HomeScreen';

import '../src/CSS/App.css'

//AOS
import AOS from 'aos'
import 'aos/dist/aos.css'
import ClientsScreen from './Screens/ClientsScreen/ClientsScreen';
import Contact from './Components/Contact/Contact';
import Services from './Screens/Services';
import Products from './Screens/Products';
import JobBank from './Screens/JobBank/JobBank';
import ContextState from './Context/ContextState';
import StatusPopUp from './Components/PopUps/StatusPopUp/StatusPopUp';
import Loader from './Components/Loader/Loader';
import ConfirmPopUp from './Components/PopUps/ConfirmPopUp/ConfirmPopUp';

function App() {

    //AOS 
    useEffect(() => {
        AOS.init({
            duration : 1500,
            once: true,
            mirror: false,
            offset: 0
        })
    }, [])

  return (
    <Fragment>
        <ContextState>
            <BrowserRouter>
                <header>
                    <Navbar></Navbar>
                </header>
                <body>
                    <Routes>
                        {/* Home Screen */}
                        <Route path="/" element={<HomeScreen/>} exact/>

                        {/* Our Clients Screen */}
                        <Route path="/Products" element={<Products/>} exact/>

                        {/* Our Clients Screen */}
                        <Route path="/OurClients" element={<ClientsScreen/>} exact/>

                        {/* Our Clients Screen */}
                        <Route path="/JobBank" element={<JobBank/>} exact/>

                        {/* Our Clients Screen */}
                        <Route path="/Solutions" element={<Services/>} exact/>

                        {/* Our Clients Screen */}
                        <Route path="/ContactUs" element={<Contact/>} exact/>
                    </Routes>

                    {/* status popup */}
                    <StatusPopUp/>

                    {/* loader */}
                    <Loader/>

                    {/* confirm popup */}
                    <ConfirmPopUp/>
                </body>
                <footer>
                    <Fotter></Fotter>
                </footer>
            </BrowserRouter>
        </ContextState>
    </Fragment>
  );
}

export default App;
