import React, { useContext, useState } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Context from '../../Context/Context'

import './Contact.css'

const Contact = () => {
    //CONTEXT
    const { sendEmail, showLoaderF, hideLoader } = useContext(Context)

    const [mailData, setMailData] = useState({})

    const newEmail = async() => {
        showLoaderF()
        
        setTimeout(async() => {
            if(mailData.name == null){
                hideLoader()
                return
            }

            if(mailData.lastName == null){
                hideLoader()
                return
            }

            if(mailData.email == null){
                hideLoader()
                return
            }

            if(mailData.business == null){
                hideLoader()
                return
            }

            if(mailData.message == null){
                hideLoader()
                return
            
            }

            const newMailBody = {
                name: mailData.name,
                lastName: mailData.lastName,
                email:  mailData.email,
                business:  mailData.business,
                message:  mailData.message
            }
    
            await sendEmail(
                '0135bfe0-2a0b-11ed-9b51-039b8060f0b7',
                newMailBody
            )
    
            hideLoader()
        }, 1500);


    }

  return (
    <Fragment>
        <div class="contact-us-container">
            <div class="contact-us-tittle">
                Contáctanos
            </div>
            <div class="contact-us-form">
                <div class="contact-us-form-name-inp">
                    <label>Nombre(s) *</label>
                    <input
                        type="text"
                        required
                        onChange={(e) => {
                            mailData.name = e.target.value
                        }}
                    ></input> 
                </div>
                <div class="contact-us-form-lastname-inp">
                    <label>Apellido(s) *</label>
                    <input
                        type="text"
                        onChange={(e) => {
                            mailData.lastName = e.target.value
                        }}
                    ></input>
                </div>
                <div class="contact-us-form-email-inp">
                    <label>Email *</label>
                    <input
                        type="text"
                        onChange={(e) => {
                            mailData.email = e.target.value
                        }}
                    ></input>
                </div>
                <div class="contact-us-form-business-inp">
                    <label>Empresa *</label>
                    <input
                        type="text"
                        onChange={(e) => {
                            mailData.business = e.target.value
                        }}
                    ></input>
                </div>
                <div class="contact-us-form-message-txta">
                    <label>Mensaje *</label>
                    <textarea
                        type="text"
                        onChange={(e) => {
                            mailData.message = e.target.value
                        }}
                    ></textarea>
                </div>
                <div 
                    class="contact-us-form-send-btn"
                    onClick={
                        (e) => {
                            newEmail()
                        }}
                >
                    <span>Enviar</span>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default Contact