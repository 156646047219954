import React, { Fragment, useState } from 'react'

//STYLE
import './Footer.css'
import '../PopUps/PopUpForms/popUpForm.css'

//IMAGES
import LOGOKAANW from'../../IMG/LOGO/LogoKaanBlanco.png'
import PopUpForm from '../PopUps/PopUpForms/PopUpForm'

const Fotter = () => {

    const [showForm, setShowForm] = useState(false)

    const openForm = () => {
        setShowForm(true)
    }

    const closeForm = () => {
        setShowForm(false)
    }

  return (
    <Fragment>
        <div class="footer-container">
            <div class="footer-sec-1">
                <label>Dirección</label>
                {/* <label>+52 XXXXXXXXXX</label> */}
                <label class="kaan-mail">contacto@kaanmx.com.mx</label>
                <label>Calzada de Guadalupe 94, col peralvillo cp 06220 delegación Cuauhtémoc.</label>
            </div>
            <div class="footer-sec-2">
                <img src={LOGOKAANW}></img>
            </div>
            <div class="footer-sec-3">
                <span>© 2022 Kaan <br/> All Rights Reserved.</span>
                <br></br>
                {
                    sessionStorage.getItem("admin") ? (
                        <label
                            onClick={(e) => {
                                sessionStorage.clear()
                                window.location = '/'
                            }} 
                        >
                            LOG OUT
                        </label>
                    ) : (
                        <label
                            onClick={(e) => {
                                openForm()
                            }}
                        >
                            LOG IN
                        </label>
                    )
                }
            </div>
        </div>

        {/* Form Container */}
        <PopUpForm
            form="login"
            isOpen={showForm}
            closeForm={closeForm}
        />

        {/* User Data Modal */}
        {/* <UserModal
            form='loginSucess'
            isOpen={showForm}
            closeForm={closeForm}
        /> */}

    </Fragment>
  )
}

export default Fotter