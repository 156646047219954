import React, { useContext } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Context from '../../../Context/Context'

// style
import './StatusPopUp.css'

const StatusPopUp = () => {

    //CONTEXT
    const { reqStatus } = useContext(Context)

    const reqMsg = sessionStorage.getItem('reqMsg')
    return (
        <Fragment>
            <div class={`popup ${reqStatus ? 'form-open' : ''}`}>
                <div class="popupMsg">
                    {
                        reqMsg ? (
                            <label>Sucess !</label>
                        ) : (
                            <label>Failed</label>
                        )
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default StatusPopUp