import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'


//IMAGES
import LOGO from '../../IMG/LOGO/LOGOKAAN.png'

//STYLES
import './Navbar.css'

const Navbar = () => {

    const [mobileMenu, setMobileMenu] = useState(false)
 
    const showMobileMenu = () => {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true)
    }

  return (
    <Fragment>
        <div class="navbar-container">

            <div class="img-container" data-aos="zoom-in" data-aos-duration="1000">
            <Link to="/" class="link">
                <img src={LOGO}></img>
            </Link>
            </div>


            <div 
                class="mobile-menu-btn"
                onClick={(e) => {
                    showMobileMenu()
                }}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div class={mobileMenu ? "mobile-menu-container" : "mobile-menu-container-hidden"}>
                <Link to="/" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Nosotros</label>
                </Link>
                <Link to="/Products" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Productos</label>
                </Link>
                <Link to="/OurClients" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Clientes</label>
                </Link>
                <Link to="/JobBank" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Bolsa de trabajo</label>
                </Link>
                <Link to="/Solutions" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Soluciones</label>
                </Link>
                <Link to="/ContactUs" class="link" onClick={(e) => {showMobileMenu()}}>
                    <label>Contáctanos</label>
                </Link>
            </div>

            <div class="menu-container">
                <Link to="/" class="link">
                    <label>Nosotros</label>
                </Link>
                <Link to="/Products" class="link">
                    <label>Productos</label>
                </Link>
                <Link to="/OurClients" class="link">
                    <label>Clientes</label>
                </Link>
                <Link to="/JobBank" class="link">
                    <label>Bolsa de trabajo</label>
                </Link>
                <Link to="/Solutions" class="link">
                    <label>Soluciones</label>
                </Link>
                <Link to="/ContactUs" class="link">
                    <label>Contáctanos</label>
                </Link>
            </div>
      </div>
    </Fragment>
  )
}

export default Navbar