import {
    GET_TOKEN,

    //products
    GET_PRODUCTS,
    NEW_PRODUCT,
    DELETE_PRODUCT,
    DELETE_PRODUCT_IMG,
    PUT_PRODUCT_IMAGES,

    //clients
    GET_CLIENTS,
    NEW_CLIENT,
    DELETE_CLIENT,
    DELETE_CLIENT_IMG,
    PUT_CLIENT_IMAGES,


    // mailer
    SEND_EMAIL,


    LOGIN,
    GET_USER_DATA,

    REQ_STATUS,
    SHOW_LOADER,
    SHOW_MSG,
    ITEM,
    REQUEST
} from './Types';

export default (state, action) =>{
    const {payload, type} = action;

    switch (type){
        case GET_TOKEN:
            return {
                ...state,
                token: payload
            }

        case GET_PRODUCTS:
            return {
                ...state,
                kaanProducts: payload
            }

        case NEW_PRODUCT:
            return {
                ...state,
                result: payload
            }

        case DELETE_PRODUCT:
            return {
                ...state,
                result: payload
            }

        case PUT_PRODUCT_IMAGES:
            return {
                ...state,
                result: payload
            }

        case DELETE_PRODUCT_IMG:
            return {
                ...state,
                result: payload
            }


        //clients
        case GET_CLIENTS:
            return {
                ...state,
                kaanClients: payload
            }

        case NEW_CLIENT:
            return {
                ...state,
                result: payload
            }

        case DELETE_CLIENT:
            return {
                ...state,
                result: payload
            }

        case PUT_CLIENT_IMAGES:
            return {
                ...state,
                result: payload
            }

        case DELETE_CLIENT_IMG:
            return {
                ...state,
                result: payload
            }


        // admin login
        case LOGIN:
            return {
                ...state,
                userData: payload
            }

        case GET_USER_DATA: 
        return {
            ...state,
            userData: payload
        }


        // send email
        case SEND_EMAIL:
            return {
                ...state,
                result: payload
            }


        // status 
        case REQ_STATUS:
            return {
                ...state,
                reqStatus: payload
            }

        case SHOW_LOADER:
            return {
                ...state,
                showLoader: payload
            }

        case SHOW_MSG: 
            return {
                ...state,
                confirmMsg: payload
            }

        case ITEM: 
            return {
                ...state,
                item: payload
            }

        case REQUEST:
            return {
                ...state,
                req: payload
            }
        
        default: return state
    }
}